import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
  }
  // use your custom style for ".popup-content"
  &-content {
    width: 474px;
    border-radius: 13px;
  }

  @media screen and (max-width: 600px) {
    &-content {
      width: 90vw;
    }
  }
`;

const FormLabel = styled.label`
  margin-top: 1.25rem;
  font-weight: 400;
  padding-left: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

const FormInput = styled.input`
  margin-top: 1px;
  padding: 0.5rem;
  //border: 1px solid #ccc;
  border-radius: 6px;
  background: #f5f5f5;
  width: 100%;

  :focus {
    border-color: #7eb0db;
    outline: 1px solid transparent;
    outline-offset: 2px;
  }
`;

const SubscribeForm = () => {
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mailChimpStatus, setMailChimpStatus] = useState();
  const closeModal = () => setOpen(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSaving(true);

    addToMailchimp(data.email, {
      FNAME: data.firstname,
      LNAME: data.lastname,
      MMERGE3: data.company,
    })
      .then((data) => {
        setMailChimpStatus(data);
      })
      .catch((error) => {
        setMailChimpStatus({
          result: "error",
          msg: error.toString(),
        });
      });
  };

  const handleSubscribeMessage = (result) => {
    setMailChimpStatus();
    setIsSaving(false);
    setOpen(result !== "success");
  };
  return (
    <div className="flex items-center justify-center mt-5 ">
      <button
        type="button"
        className="w-full h-full bg-red1 rounded-full bg-actionGreen
        text-white px-5 py-2 text-lg font-semibold focus:outline-none"
        onClick={() => setOpen(true)}
      >
        Subscribe
      </button>
      <StyledPopup
        open={open}
        closeOnDocumentClick
        onClose={() => closeModal()}
        nested
      >
        <div>
          <div
            className={`${
              isSaving ? "block" : "hidden"
            } absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 text-white`}
          >
            {mailChimpStatus === undefined && (
              <div className="bg-white text-black p-10 flex flex-col items-center justify-center">
                <p>Subscribing...</p>
              </div>
            )}
            {mailChimpStatus && (
              <div className="bg-white text-black p-10 flex flex-col items-center justify-center">
                <p className="mb-5">{mailChimpStatus.msg}</p>
                <button
                  className="bg-blue-800 text-white py-2 px-6 rounded-sm"
                  onClick={() => handleSubscribeMessage(mailChimpStatus.result)}
                >
                  OK
                </button>
              </div>
            )}
          </div>
          <button
            className="flex bg-black absolute -top-4 -right-4 w-8 h-8 items-center cursor-pointer
            justify-center rounded-full border-1 text-white border-white shadow-lg focus:outline-none"
            onClick={closeModal}
          >
            <MdClose />
          </button>
          <div className="px-12">
            <p className="mt-8 mb-11 text-2xl text-actionBlue font-bold">
              Get Email Updates
            </p>
            <form id="subscribe" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col items-start text-actionBlue mb-14">
                <FormLabel className="text-lg">First Name</FormLabel>
                <FormInput
                  type="text"
                  {...register("firstname", { required: true })}
                  onChange={(e) => {setValue("firstname", e.target.value);}}
                />
                {errors.firstname && (
                  <span role="alert" className="text-xs text-red-600 font-sans">
                    Field is required
                  </span>
                )}
                <FormLabel className="font-semibold">Last Name</FormLabel>
                <FormInput
                  type="text"
                  {...register("lastname", { required: true })}
                  onChange={(e) => {setValue("lastname", e.target.value);}}
                />
                {errors.lastname && (
                  <span role="alert" className="text-xs text-red-600 font-sans">
                    Field is required
                  </span>
                )}
                <FormLabel className="font-semibold">Company Name</FormLabel>
                <FormInput
                  type="text"
                  {...register("company", { required: true })}
                  onChange={(e) => {setValue("company", e.target.value);}}
                />
                {errors.company && (
                  <span role="alert" className="text-xs text-red-600 font-sans">
                    Field is required
                  </span>
                )}
                <FormLabel className="font-semibold">Email Address</FormLabel>
                <FormInput
                  type="email"
                  {...register("email", { required: true })}
                  onChange={(e) => {setValue("email", e.target.value);}}
                ></FormInput>
                {errors.email && (
                  <span role="alert" className="text-xs text-red-600 font-sans">
                    Field is required
                  </span>
                )}
                <button
                  className="mt-8 bg-actionBlue py-2 px-12 text-white rounded-lg"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </StyledPopup>
    </div>
  );
};

export default SubscribeForm;
